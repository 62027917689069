export const environment = {
    production: true,
    int: false,
    qual: true,
    demo: false,
    dev: false,
    API_BASE_URL: 'https://625pt4bktj.execute-api.eu-west-1.amazonaws.com/qual',
    API_BASE_LOCAL_URL: 'http://127.0.0.1:8000',
    URL_MYVAM: ['https://localhost/Appli/applications/espace/render/default.aspx',
              'https://localhost/Appli/applications/Publication/New%20Render/Default.aspx',
              'https://qual-myvam.vallourec.net/Appli/applications/espace/render/default.aspx',
              'https://qual-myvam.vallourec.net/Appli/applications/Publication/New%20Render/Default.aspx',
              'https://myvam.vamservices.com/Appli/applications/Publication/New%20Render/Default.aspx',
              'https://myvam.vamservices.com/Appli/applications/espace/render/default.aspx',
              'https://localhost/Appli/Applications/Publication/Search/Result/default.aspx?TypeSearch=Simple',
              'https://qual-myvam.vallourec.net/Appli/Applications/Publication/Search/Result/default.aspx?TypeSearch=Simple',
              'https://myvam.vamservices.com/Appli/Applications/Publication/Search/Result/default.aspx?TypeSearch=Simple'],
    users:  [
      {
        'login': 'ocaron_admin',
        'token': 'Y23NtkCT35heLFCg'
      },
      {
        'login': 'ocaron_ara1',
        'token': '3AEfxvE55euCrGty'
      },
      {
        'login': 'ocaron_reader1',
        'token': 'hQf3MUyXwaAjXWhz'
      },
      {
        'login': 'ocaron_ara2',
        'token': 'ULgnK5UZRVsx55hD'
      },
      {
        'login': 'ocaron_reader2',
        'token': 'XWV4gjNgwPKFweWC'
      },
      {
        'login': 'ocaron_R&D1',
        'token': 'kS6RaQLhQG7Fmq53'
      },
      {
        'login': 'ocaron_R&D2',
        'token': 'AxHXNv5JDEvbM8Qc'
      },
      {
        'login': 'ocaron_R&D3',
        'token': '9Y3BSsaJ5yGzhv2G'
      }
    ],
    URL_MYVAM_REDIRECT: "https://myvam.vamservices.com"
  };
