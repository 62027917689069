import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, empty, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {environment} from 'src/environments/environment';
import {AuthService} from './auth.service';
import {ServicesNamesConstants} from './services-names.constants';
import {Router} from '@angular/router';
import {UsersReponse} from './data/user-response';
import {User} from './data/User';
import {RestrictedDocument} from './data/restricted-document';
import {RestrictedDocumentResponse} from './data/restricted-document-response';

@Injectable({
  providedIn: 'root'
})
export class UserRestService {
  static readonly DEBUG: boolean = false;

  readonly API_BASE_URL: string = environment.API_BASE_URL;
  // readonly API_BASE_LOCAL_URL: string = environment.API_BASE_LOCAL_URL;

  readonly API_USER: string = '/user';
  readonly RESTRICTED_DOCUMENT_ACCESS = '/restrict-document-access';

  // update License Light rights
  updatedUser: User = null;

  constructor(public http: HttpClient,
              public authService: AuthService,
              public router: Router) {
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.API_BASE_URL + this.API_USER + '/users',
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_USERS)).pipe(
        map((response: User[]): User[] => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  getSkillsMatrixUsers(): Observable<UsersReponse> {
    return this.http.get<UsersReponse>(this.API_BASE_URL + this.API_USER + '/skills-matrix',
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_SKILLS_MATRIX_USERS)).pipe(
        map((response: UsersReponse): UsersReponse => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }


  getReviewers(id_tsli): Observable<User[]> {
    return this.http.get<User[]>(this.API_BASE_URL + this.API_USER + `/reviwer?id_tsli=${id_tsli}`,
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_USERS)).pipe(
        map((response: User[]): User[] => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  getAuthors(id_tsli): Observable<User[]> {
    return this.http.get<User[]>(this.API_BASE_URL + this.API_USER + `/authors?id_tsli=${id_tsli}`,
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_USERS)).pipe(
        map((response: User[]): User[] => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  getUserRights(id_user): Observable<any> {
    return this.http.get<any>(this.API_BASE_URL + this.API_USER + `/user-rights?id_user=${id_user}`,
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_USERS)).pipe(
        map((response: any): any => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  getUserLicenseLightRights(id_user): Observable<any> {
    return this.http.get<any>(this.API_BASE_URL + this.API_USER + `/licensee-light-rights?id_user=${id_user}`,
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_USERS)).pipe(
        map((response: any): any => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  getLicensseeNumbers(): Observable<any> {
  return this.http.get<string[]>(this.API_BASE_URL + this.API_USER + '/licensee-numbers',
      this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_USERS)).pipe(
      map((response: any): string[] => {
        return response['licensee'];
      }),
      catchError((err) => {
        if (err.status == 401) {
          this.router.navigate([`tsli/Unauthorized`]);
        }
        return of();
      })
    );
  }

  updateRights(userRights): Observable<any> {
    return this.http.put<any>(this.API_BASE_URL + this.API_USER + '/update-product-user-right',
      userRights, this.authService.getAuthorization(ServicesNamesConstants.USERS_UPDATE_USER)).pipe(
        map((response: any): any => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  updateLicenseLightRights(userLightRights): Observable<any> {
    console.log('here:', userLightRights);
    return this.http.put<any>(this.API_BASE_URL + this.API_USER + '/update-tslis-user-right',
    userLightRights, this.authService.getAuthorization(ServicesNamesConstants.USERS_UPDATE_USER)).pipe(
        map((response: any): any => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  updateUser(user): Observable<any> {
    return this.http.put<any>(this.API_BASE_URL + this.API_USER + '/update-user',
        user, this.authService.getAuthorization(ServicesNamesConstants.USERS_UPDATE_USER)).pipe(
        map((response: any): any => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  updateUserMail(user): Observable<any> {
    return this.http.put<any>(this.API_BASE_URL + this.API_USER + '/update-user-mail',
        user, this.authService.getAuthorization(ServicesNamesConstants.USERS_UPDATE_USER_MAIL)).pipe(
        map((response: any): any => {
          console.log('response', response);
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  deleteUsers(users): Observable<any> {
    return this.http.post<User[]>(this.API_BASE_URL + this.API_USER + '/users-to-delete',
        {users_to_delete: users},
        this.authService.getAuthorization(ServicesNamesConstants.USERS_DELETE_USERS)).pipe(
        map((response: User[]): User[] => {
          return response;
        })
        , catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        }));
  }

  setUpdatedUser(user: User) {
    this.updatedUser = user;
  }

  getUpdatedUser() {
    return this.updatedUser;
  }

  updateIsVamFamily(isVamFamily, id_user): Observable<any> {

    return this.http.put<any>(this.API_BASE_URL + this.API_USER + '/update-is-vam-family', {
      id_user: id_user,
      is_vam_family: isVamFamily
    }, this.authService.getAuthorization(ServicesNamesConstants.USERS_UPDATE_USER_MAIL)).pipe(
        map((response: any): any => {
          console.log('response', response);
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return of();
        })
    );
  }

  addOrUpdateRestrictedUsers(documentId: number, users: User[]): Observable<any> {
    const userIds = users.map(user => user.id);
    return this.http.post<RestrictedDocument[]>(this.API_BASE_URL + '/restrict-document-access/add_restricted_access/' + documentId, userIds,
        this.authService.getAuthorization(ServicesNamesConstants.USERS_ADD_RESTRICTED_ACCESS)).pipe(
        map((response: RestrictedDocument[]): RestrictedDocument[] => {
          return response;
        })
        , catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        }));
  }

  getRestrictedUsersForDocument(id_document): Observable<User[]> {
    return this.http.get<User[]>(this.API_BASE_URL + '/restrict-document-access/restricted_users_by_document/' + id_document,
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_RESTRICTED_USERS)).pipe(
        map((response: User[]): User[] => {
          console.log('restricted users:', response);
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

  getRestrictedUsersByDocTypeAndRefence(documentType: string, reference: string): Observable<RestrictedDocumentResponse> {
    const params = new HttpParams()
        .set('document_type_param', documentType)
        .set('reference_param', reference);
    return this.http.get<RestrictedDocumentResponse>(this.API_BASE_URL + '/restrict-document-access/restricted-users-by-docTypeAndReference',
        {params: params, ...this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_RESTRICTED_USERS)}).pipe(
        map((response: RestrictedDocumentResponse): RestrictedDocumentResponse => {
          console.log('restricted users and document:', response);
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/by-reference/${documentType}/${reference}`]);
          }
          return empty();
        })
    );
  }

  getUsersToRestrict(): Observable<User[]> {
    return this.http.get<User[]>(this.API_BASE_URL + '/restrict-document-access/users_to_restrict',
        this.authService.getAuthorization(ServicesNamesConstants.USERS_GET_USERS_TO_RESTRICT)).pipe(
        map((response: User[]): User[] => {
          return response;
        }),
        catchError((err) => {
          if (err.status == 401) {
            this.router.navigate([`tsli/Unauthorized`]);
          }
          return empty();
        })
    );
  }

}




