import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Observable, empty } from 'rxjs';
import { ServicesNamesConstants } from './services-names.constants';
import { Comment } from './data/comment';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommentsRestService {
  static readonly DEBUG: boolean = false;

  readonly API_BASE_URL: string = environment.API_BASE_URL;

  readonly TSLI: string = '/tsli';

  constructor(public http: HttpClient,
              public authService: AuthService,
              public router: Router) {
  }

  addComment(comment:Comment): Observable<any> {
    let body = {};
    if(comment.chapter != undefined) {
      body = {
        "id_user": comment.commentator.id,
        "id_tsli": comment.tsli.idTsli,
        "comment": comment.comment,
        "comment_date": comment.commentDate,
        "id_chapter": comment.chapter.id_chapter,
        "is_revision_comment": comment.is_revision_comment
      }
    }
    else {
      body = {
        "id_user": comment.commentator.id,
        "id_tsli": comment.tsli.idTsli,
        "comment": comment.comment,
        "comment_date": comment.commentDate,
        "is_revision_comment": comment.is_revision_comment
      }
    }
    return this.http.post<any>(this.API_BASE_URL + this.TSLI + "?comments",
      body,
      this.authService.getOptions(ServicesNamesConstants.TSLI_CREATE_COMMENT)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err, caught) => {
        if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
        return empty();
      }));
  }

  getAllComments(tsli): Observable<Comment[]> {
    return this.http.get<Comment[]>(this.API_BASE_URL + this.TSLI
      + "?is_revision_comment&comments&comment_id_tsli=" + tsli,
      this.authService.getOptions(ServicesNamesConstants.TSLI_GET_ALL_COMMENTS)).pipe(
      map((response: Comment[]): Comment[] => {
        return response;
      })
    , catchError((err, caught) => {
      if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
      return empty();
    }));
  }

  deleteComments(id_tsli, id_chapter, only_tsli): Observable<any>{
    let params = "?comments&comment_id_tsli=" + id_tsli + "&only_tsli=" + only_tsli
    if(id_chapter != undefined) params = params + "&id_chapter=" +id_chapter
    return this.http.delete<any>(this.API_BASE_URL + this.TSLI
      + params,
      this.authService.getOptions(ServicesNamesConstants.TSLI_DELETE_COMMENTS)).pipe(
      map((response: any): any => {
        return response;
      })
    , catchError((err, caught) => {
      if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
      return empty();
    }));
  }

  updateComment(id_comment, value){
    let body={"id_comment": id_comment, "value": value}
    return this.http.put<any>(this.API_BASE_URL + this.TSLI + "?comments",
      body,
      this.authService.getOptions(ServicesNamesConstants.TSLI_UPDATE_COMMENT)).pipe(
      map((response: any): any => {
        return response;
      })
      , catchError((err, caught) => {
        if(err.status == 401) this.router.navigate([`tsli/Unauthorized`])
        return empty();
      }));
  }
}
